.bg-background-black {
    background-color: rgba(20,20,25, 1.0);
    transition: background-color linear 150ms;
}

.bg-background-black.active {
    background-color: rgba(20,20,25, 1.0);
}

@media(min-width: 991px) {
    .bg-background-black {
        background-color: rgba(20,20,25, 0.0);
    }
    .bg-background-black.active {
        background-color: rgba(20,20,25, 0.7);
    }
}