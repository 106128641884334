.carousel {
    display: flex;
    justify-content: center;
}

.thumbs.animated {
    padding-left: 0;
}

.carousel .thumb {
    border-color: #333;
}

.carousel .thumb.selected {
    border-color: #fff;
}