@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400|Permanent+Marker);
/* Import Permanent Marker and Open Sans fonts */

body {
  font-family: 'Open Sans', sans-serif;
  background: url(/static/media/what-the-hex-dark.00dc0de3.png) repeat;
  background-color: rgb(20,20,25);
  background-attachment: fixed;
}

/* add Google Fonts to elements */
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p, li, a {
    font-family: 'Open Sans', sans-serif;
}

a {
    color: #00FFFF;
    text-decoration: none;
    -webkit-transition: -webkit-text-decoration 300ms linear;
    transition: -webkit-text-decoration 300ms linear;
    transition: text-decoration 300ms linear;
    transition: text-decoration 300ms linear, -webkit-text-decoration 300ms linear;
}

a:hover {
    color: #00FFFF;
    text-decoration: underline;
}

.font--marker{
    font-family: 'Permanent Marker', sans-serif;
}

/* simple page transitions - enter transition 300ms, exit 150ms */
.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: opacity 300ms linear 150ms, -webkit-transform 300ms ease-out 150ms;
    transition: opacity 300ms linear 150ms, -webkit-transform 300ms ease-out 150ms;
    transition: opacity 300ms linear 150ms, transform 300ms ease-out 150ms;
    transition: opacity 300ms linear 150ms, transform 300ms ease-out 150ms, -webkit-transform 300ms ease-out 150ms;
}

.fade-exit {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.fade-exit.fade-exit-active {
    opacity: 0;
    -webkit-transform: translateY(10px);
            transform: translateY(10px);
    -webkit-transition: opacity 150ms linear, -webkit-transform 150ms ease-in;
    transition: opacity 150ms linear, -webkit-transform 150ms ease-in;
    transition: opacity 150ms linear, transform 150ms ease-in;
    transition: opacity 150ms linear, transform 150ms ease-in, -webkit-transform 150ms ease-in;
}

.bg-background-black {
    background-color: rgba(20,20,25, 1.0);
    -webkit-transition: background-color linear 150ms;
    transition: background-color linear 150ms;
}

.bg-background-black.active {
    background-color: rgba(20,20,25, 1.0);
}

@media(min-width: 991px) {
    .bg-background-black {
        background-color: rgba(20,20,25, 0.0);
    }
    .bg-background-black.active {
        background-color: rgba(20,20,25, 0.7);
    }
}
.carousel {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.thumbs.animated {
    padding-left: 0;
}

.carousel .thumb {
    border-color: #333;
}

.carousel .thumb.selected {
    border-color: #fff;
}
